<template>
  <CRow class="text-center">
    <CCol col="12">
      <vue-element-loading
        :active="loading"
        :text="loadingtext"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <CRow>
        <CCol class="col-12">
          <span class="insertInputRequired">*</span>
          <input
            type="text"
            v-model="$v.firstName.$model"
            placeholder="نام"
            class="insertInput"
          />
          <label
            class="error"
            v-if="$v.firstName.$error"
            v-text="
              $v.firstName.onlyChar
                ? 'نام خود را وارد کنید'
                : 'تنها مجاز به وارد کردن حروف فارسی هستید'
            "
          ></label>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol class="col-12">
          <span class="insertInputRequired">*</span>
          <input
            type="text"
            v-model="$v.lastName.$model"
            placeholder="نام خانوادگی"
            class="insertInput"
          />
          <label
            class="error"
            v-if="$v.lastName.$error"
            v-text="
              $v.lastName.onlyChar
                ? 'نام خانوادگی خود را وارد کنید'
                : 'تنها مجاز به وارد کردن حروف فارسی هستید'
            "
          ></label>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol class="col-12">
          <input
            type="text"
            v-model="tel"
            placeholder="تلفن ثابت"
            class="insertInput"
          />
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol class="col-12">
          <input
            type="text"
            v-model="address"
            placeholder="آدرس"
            class="insertInput"
          />
        </CCol>
      </CRow>
      <br />
      <CRow class="radioBox">
        <label style="margin: 0px 17px 0 16px">
          <span
            class="insertInputRequired"
            style="position: relative; right: -19px; top: -6px"
            >*</span
          >
          جنسیت
        </label>
        <CInputRadioGroup
          inline
          :options="[
            {
              value: 'Female',
              label: 'خانم',
            },
            {
              value: 'Male',
              label: 'آقا',
            },
          ]"
          :checked.sync="$v.genderType.$model"
        />
        <label
          style="position: inherit"
          class="error"
          v-if="$v.genderType.$error"
          >جنسیت خود را وارد کنید</label
        >
      </CRow>
      <br />
      <CRow class="mt-2">
        <CCol class="col-12">
          <date-picker
            color="var(--secondary)"
            v-model="persianBirthDate"
            placeholder="تاریخ تولد"
          ></date-picker>
        </CCol>
      </CRow>
      <br />
      <CRow class="mt-2">
        <CCol class="col-12" style="color: #ccc">
          <v-select
            block
            dir="rtl"
            v-model="education"
            :options="educationOptions"
            placeholder="انتخاب تحصیلات..."
          ></v-select>
        </CCol>
      </CRow>
      <br />
      <br />
      <br />
      <CRow>
        <CCol class="col-9">
          <CButton
            style="background-color: #0d722f; color: white"
            block
            @click="editProfile"
            >ذخیره تغییرات</CButton
          >
        </CCol>
        <CCol class="col-1"></CCol>
        <CCol class="col-2">
          <CButton
            style="background-color: red; color: white"
            block
            @click="
              () => {
                $emit('close');
              }
            "
            >لغو</CButton
          >
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from "vuex";

import { required, minLength, helpers } from "vuelidate/lib/validators";
import MaskedInput from "vue-text-mask";
const onlyChar = helpers.regex("onlyChar", /^[ _آ-یءچ]+$/);
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DatePicker } from "v-calendar";
import { getCurrentUser } from "../../utils/index";



export default {
  name: "agentProfile",
  components: {
    vSelect,
  },
  data: () => ({
    loading: false,
    loadingtext: "در حال ارسال اطلاعات",
    firstName: "",
    lastName: "",
    tel: "",
    address: "",
    persianBirthDate: "",
    education: "",
    genderType: "",
    educationOptions: [
      {
        value: "DownCycle",
        label: "زیر سیکل",
      },
      {
        value: "Cycle",
        label: "سیکل",
      },
      {
        value: "Diploma",
        label: "دیپلم",
      },
      {
        value: "AssociateDegree",
        label: "فوق دیپلم",
      },
      {
        value: "Expert",
        label: "کارشناسی",
      },
      {
        value: "Masters",
        label: "کارشناسی ارشد",
      },
      {
        value: "Doctorate",
        label: "دکترا",
      },
      {
        value: "UperDoctorate",
        label: "بالاتر از دکتر",
      },
    ],
  }),
  watch: {},
  validations: {
    firstName: {
      required,
      onlyChar,
    },
    lastName: {
      required,
      onlyChar,
    },
    genderType: {
      required,
    },
  },
  methods: {
    ...mapActions("dashboard", ["updateAgent", "GetAgentProfile"]),
    async editProfile() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          group: "auth",
          title: "پیام سیستم",
          text: "قسمت های ستاره دار را تکمیل کنید <br /><br />",
          type: "info",
        });
        return;
      } else {
        this.loading = true;
        this.loadingtext = "در حال ذخیره تغییرات ...";
        let agent = {
          firstName: this.firstName,
          lastName: this.lastName,
          tel: this.tel,
          address: this.address,
          persianBirthDate: this.persianBirthDate,
          education: this.education.value,
          gender: this.genderType,
        };
        let result = await this.updateAgent({ agent: agent });
        this.loading = false;
        this.$emit("close");
        if (result) {
          getCurrentUser().fullName = `${agent.firstName} ${agent.lastName}`;
          this.$notify({
            title: "پیام سیستم",
            text: "تغییرات ذخیره شد.",
            type: "success",
          });
        } else {
          this.$notify({
            title: "خطا ثبت نام",
            text: "اطلاعات ارسالی معتبر نیست <br /><br />",
            type: "error",
          });
        }
      }
    },
    async fetchData() {
      this.loading = true;
      var result = await this.GetAgentProfile({});
      if (result) {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.tel = result.tel;
        this.address = result.address;
        this.persianBirthDate = result.birthDate;
        this.genderType = result.gender;
        this.education = this.educationOptions.filter((item) => {
          return item.value == result.education;
        })[0];
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات",
          type: "error",
        });
      }
    },
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.bgLogin {
  background-image: url("/img/bgLogIn.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #7c47aa;
}
.bgLogo {
  background-image: url("/img/saharaLogo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.rowHeight {
  height: 100vh;
}
html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
.row {
  margin-left: 0%;
  margin-right: 0%;
}
.radioBox {
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px 0px #bbb;
  box-shadow: 0px 0px 5px 0px #bbb;
  padding: 4px;
  width: 93%;
  margin-right: 16px !important;
  border-radius: 4px;
  height: 32px;
}
.verifyInput {
  width: 40px;
  height: 30px;
  float: left;
  box-shadow: 0px 0px 2px 1px #bbb;
  border: none;
  text-align: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.insertInput {
  float: right;
  width: 100%;
  border: none;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px #bbb;
  margin: 3px 0px 3px 0px;
  padding-right: 5px;
  outline: none;
}
.insertInputRequired {
  float: right;
  color: red;
  position: absolute;
  right: 17px;
  top: 0px;
}
.error {
  position: absolute;
  top: 9%;
  left: 15px;
  color: #ff00009c;
}
</style>
