<template>
  <CHeader class="flex items-center" with-subheader style="height: 6dvh">
    <CToggler in-header class="ml-3 d-lg-none" @click="dotoggleSidebarMobile" />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="dotoggleSidebarDesktop"
    />
    <div class="y-center-g-10 text-black-50" v-if="userHaveInventory()">
      <button
        v-if="this.userTypeName != 'Agency'"
        class="btn btn-primary"
        @click="$router.push('userAccountManagement')"
      >
        افزایش موجودی
      </button>
      <h6
        v-if="inventoryState"
        class="m-0"
        style="color: #f80"
        :class="{
          'out-of-balance': inventory < 3000,
        }"
      >
        <span class="font-sm" style="color: #000"> موجودی:</span>
        <i class="font-xl mr-2">{{ inventory.toLocaleString() }}</i>
        <span class="font-sm" style="color: #000">ریال</span>
      </h6>
      <h4 v-else class="faildInventry">
        {{ inventoryMessage }}
      </h4>
    </div>

    <button
      v-if="this.userTypeName == 'Advicer'"
      class="expertise-btn btn btn-success"
      @click="$router.push('userExpertiseForm')"
    >
      پروفایل تخصصی
    </button>
    <CHeaderNav style="position: absolute; left: 0vw; top: 0">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import { mapMutations, mapActions } from "vuex";
import { getCurrentUser } from "../utils/index";

export default {
  name: "TheHeader",
  data() {
    return {
      userTypeName: "",
      expertiseState: null,
      title: "",
      code: "",
      inventory: 0,
      inventoryState: true,
      inventoryMessage: "",
      userCount: {
        farmCount: 0,
        agentCount: 0,
        farmerCount: 0,
        farmUserCount: 0,
        advicerCount: 0,
      },
    };
  },
  components: {
    TheHeaderDropdownAccnt,
  },
  methods: {
    ...mapActions("financialUser", ["UserInventory"]),
    ...mapMutations(["theme"]),

    dotoggleSidebarDesktop() {
      this.$store.commit("theme/toggleSidebarDesktop");
    },
    dotoggleSidebarMobile() {
      this.$store.commit("theme/toggleSidebarMobile");
    },
    dotoggledarkMode() {
      this.$store.commit("theme/toggle", "darkMode");
    },
    dotoggleasideShow() {
      this.$store.commit("theme/toggle", "asideShow");
    },
    async getCurrentUserFinancialAccountInventory() {
      let result = await this.UserInventory({});
      this.inventoryState = result.succeeded;
      this.inventoryMessage = result.message;
      if (result.succeeded) {
        this.inventory = result.data.inventory;
      }
    },
    userHaveInventory() {
      return this.userTypeName == "FarmUser" ? false : true;
    },
  },
  mounted() {
    this.userTypeName = getCurrentUser().type;
    this.title = getCurrentUser().moreInfo.typeName;
    this.code = getCurrentUser().moreInfo.code;
    if (this.userTypeName == "Advicer")
      this.expertiseState = getCurrentUser().moreInfo.expertiseState;
    if (this.userHaveInventory())
      this.getCurrentUserFinancialAccountInventory();
  },
};
</script>
<style scoped>
.countBox {
  width: 15%;
  padding-left: 5px;
  box-shadow: 0 0 3px 0 #000;
  margin-left: 16px;
  height: 20px;
  padding-right: 4px;
  padding-top: 2px;
  border-radius: 30px;
}

.countBox h5 {
  text-align: center;
  padding-top: 1px;
  color: #f80;
  font-size: 1.1em;
}

.headerBTN {
  margin: 14px 5px;
  color: #fff;
  width: 10vw;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(
    to right,
    #667eea,
    #764ba2,
    #6b8dd6,
    #8e37d7
  );
  box-shadow: 0 2px 4px 0 rgba(116, 79, 168, 0.75);
}

.headerBTN:hover {
  background-position: 100% 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.headerBTN i {
  margin: 3px 4px;
}

.sendCodeBTN {
  border: none;
  margin: 15px 5px;
  background-color: #ff9d0a;
  color: #fff;
  border-radius: 7px;
  width: 10vw;
}

.sendCodeBTN i {
  margin: 2px;
}

.sendCodeBTN:hover {
  box-shadow: 0 2px 5px 1px #000;
}

@keyframes anim-popoutin {
  0% {
    color: red;
  }

  50% {
    color: black;
    text-shadow: 0 0 3px red;
  }

  100% {
    color: red;
  }
}
.expertise-btn {
  position: absolute;
  left: 16vw;
  top: 1vh;
  padding: 10px 20px;
  border: none;
  border-radius: 21px;
  font-weight: bold;
}
</style>
